import React from 'react'
// import closeIcon from '../../shared/assets/close-icon.svg'
import SVGInline from 'react-svg-inline'
import ErrorMessageBar from '../../shared/components/AlertBar/ErrorMessageBar'
import loadsData from '../../shared/components/LoadsData'
import searchUrl from '../../shared/utility-functions/searchUrl'
import { SkeletonCard } from './ClassesCard/'
import ChevronDown from '../../shared/assets/chevron-down.svg'
import { PATIENT_ERROR_MESSAGE } from '../../shared/constants/messages'
import BackToTop from '../../shared/components/BackToTop'
import { insertBrowserHistory } from '../../Search/state/history'
// import SearchSortConnector from './Sort/SortConnector.jsx'
import { togglePreventScroll, getURLParameters, insertEmptyFilters } from '../../shared/utility-functions'
// import Filters from './Filters/Filters.jsx'
import FindFiltersConnector from '../../Search/components/Filters/FindFilters/FindFiltersConnector.jsx'
import './classes.scss'
import Pagination from '../../shared/components/Pagination/Pagination.jsx'
import LookingForClassesConnector from '../../Search/components/SearchBar/LookingFor/LookingForClassesConnector.jsx'
import searchCount from '../../shared/data-mappers/count-string.js'

export default class ClassesSearch extends React.Component {
  constructor (props) {
    super(props)
    this.props.initPage()
    this.state = {
      asideClass: null,
      desktopFixed: false,
      desktopBottom: false,
      lookingFor: '',
      showFilters: true,
      innerHeight: window.innerHeight
    }

    this.urlParameters = this.props.urlParameters || { search: {}, filters: {} }

    this.clearAllFilters = this.clearAllFilters.bind(this)
    this.setFiltersFromURL = this.setFiltersFromURL.bind(this)
    this.generateDataContent = this.generateDataContent.bind(this)
    this.generateErrorContent = this.generateErrorContent.bind(this)
    this.generateLoadingContent = this.generateLoadingContent.bind(this)
    this.handleStickyElements = this.handleStickyElements.bind(this)
    this.urlParameters = this.props.urlParameters || { filters: {} }
    this.filterOptions = this.props.filterOptions || {}
    this.changeDesktopState = this.changeDesktopState.bind(this)
    this.enterSubmit = this.enterSubmit.bind(this)
    this.setLookingFor = this.setLookingFor.bind(this)
    this.applyClicked = this.applyClicked.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.linkList = ['Neuro Wellness Classes', 'Grief Support', 'Exercise Classes', 'Maternity Classes', 'Heart Health', 'Senior Health', 'Weight Management Classes', 'Breastfeeding Classes', 'Diabetes Support']
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleStickyElements, {passive: true})
    this.setFiltersFromURL()
    window.addEventListener('popstate', this.setFiltersFromURL, {passive: true})
  }

  setFiltersFromURL () {
    let tempParams = getURLParameters()
    this.props.setFilters(tempParams.filters)
    if (tempParams.search.q) {
      this.setState({ lookingFor: tempParams.search.q })
    } else if (this.props.defaultQuery) {
      this.setState({ lookingFor: this.props.defaultQuery })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleStickyElements)
    window.removeEventListener('popstate', this.setFiltersFromURL)
  }

  handleStickyElements (e) {
    let bufferNumber = -20
    let boundings = document.getElementById('doctor-search-container') ? document.getElementById('doctor-search-container').getBoundingClientRect() : {}
    let filterBottom = document.getElementById('provider-filter-container') ? document.getElementById('provider-filter-container').getBoundingClientRect().bottom : {}
    let filterTop = document.getElementById('provider-filter-container') ? document.getElementById('provider-filter-container').getBoundingClientRect().top : {}
    let footerTop = document.getElementsByClassName('footer')[0] ? document.getElementsByClassName('footer')[0].getBoundingClientRect().top : null
    let stickyHeaderBottom = document.getElementsByClassName('fixed-filter-sort-header')[0] ? document.getElementsByClassName('fixed-filter-sort-header')[0].getBoundingClientRect().bottom : null

    if (boundings.bottom < bufferNumber && this.state.desktopFixed !== true && footerTop > 80) {
      this.setState({ desktopFixed: true })
    } else if (boundings.bottom > bufferNumber && this.state.desktopFixed !== false) {
      this.setState({ desktopFixed: false })
    } else if (stickyHeaderBottom !== null && footerTop < 80) {
      this.setState({ desktopFixed: false })
    }

    if (this.state.desktopBottom === false && filterBottom + 35 > footerTop && footerTop - 35 < window.innerHeight) {
      this.setState({ desktopBottom: true })
    } else if (this.state.desktopBottom === true && (footerTop - 35 > window.innerHeight || Math.sign(filterTop - 62) === 1)) {
      this.setState({ desktopBottom: false })
    }

    this.setState({ innerHeight: window.innerHeight })
  }

  clearAllFilters (e) {
    e.stopPropagation()
    if (this.props.enableUrl) {
      insertBrowserHistory(insertEmptyFilters(getURLParameters()))
    }
    this.props.resetFilters()
  }

  changeDesktopState (toggleFixed, toggleBottom) {
    this.setState({
      desktopFixed: toggleFixed,
      desktopBottom: toggleBottom
    })
  }

  generateDataContent () {
    return <Pagination flow='classes'
      enableUrl={this.props.enableUrl}
      isBlock={this.props.isBlock} handleStickyElements={this.handleStickyElements}
      changeDesktopState={this.changeDesktopState}
      searchItems={this.props.searchItems}
      pageSize={10}
      setPageNumber={this.props.setPageNumber} />
  }

  generateLoadingContent () {
    return <div className='doctor-search-provider-results skeleton'>
      <div className='loaded-results-container'>
        <div className='provider-box'><SkeletonCard key={1} /></div>
        <div className='provider-box'><SkeletonCard key={2} /></div>
        <div className='provider-box'><SkeletonCard key={3} /></div>
        <div className='provider-box'><SkeletonCard key={4} /></div>
        <div className='provider-box'><SkeletonCard key={5} /></div>
        <div className='provider-box'><SkeletonCard key={6} /></div>
        <div className='provider-box'><SkeletonCard key={7} /></div>
        <div className='provider-box'><SkeletonCard key={8} /></div>
        <div className='provider-box'><SkeletonCard key={9} /></div>
        <div className='provider-box'><SkeletonCard key={10} /></div>
        <div className='provider-box'><SkeletonCard key={11} /></div>
        <div className='provider-box'><SkeletonCard key={12} /></div>
      </div>
    </div>
  }

  generateErrorContent (errorMessage, fetchRetry) {
    return (
      <div className='component-container'>
        <div className='message-bar-container'>
          <ErrorMessageBar text={errorMessage} retryHandler={fetchRetry} />
        </div>
      </div>
    )
  }

  setLookingFor (lookingFor) {
    this.setState({ lookingFor: lookingFor })
  }

  enterSubmit (event) {
    let enableSearch = false
    enableSearch = this.validateEnter()

    if (enableSearch) {
      if (event.keyCode === 13) {
        this.applyClicked()
      }
    }
  }

  validateEnter () {
    if (this.state.lookingFor) {
      return true
    }
    return false
  }

  handleSearch () {
    this.applyClicked()
  }

  applyClicked () {
    let searchObject = { search: {}, filters: this.props.filters }
    searchObject.search.q = this.state.lookingFor
    if (this.props.enableUrl) {
      insertBrowserHistory(searchObject, 1)
    }
    this.props.runSearch(searchObject)
  }

  renderResultsCount () {
    return <div className='count-search-label'>
      <div className='doctor-count-label'>{this.props.dataLoaded && !this.props.filtersLoading ? this.props.searchItemCount : '__'}</div>
      {searchCount(this.props.searchItemCount, 'classes', {})} Found
    </div>
  }

  renderResultsCountMobile () {
    return (
      <div className='doctors-found-mobile'>
        {this.props.dataLoaded ? this.props.searchItemCount : '__'}&nbsp;
        {searchCount(this.props.searchItemCount, 'classes', {})} Found
      </div>
    )
  }

  toggleCollapse () {
    let currentState = this.state.mobileCollapsed
    this.setState({ mobileCollapsed: !currentState })
  }

  render () {
    let collapseStyle, collapseIcon, collapseTitleStyle
    if (this.state.mobileCollapsed) {
      collapseTitleStyle = 'collapse-title closed'
      collapseStyle = 'mobile-collapsed'
      collapseIcon = <SVGInline className='toggle-down' svg={ChevronDown} />
    } else {
      collapseTitleStyle = 'collapse-title'
      collapseStyle = 'mobile-open lookingfor'
      collapseIcon = <SVGInline className='toggle-up' svg={ChevronDown} />
    }

    return (
      <div className={`find-a-doctor-page classes-search`}>
        {this.props.headline && this.props.headline.length && <h1 className={`block-header page-header`}>{this.props.headline}</h1>}
        {
          !this.props.searchOptions.showLookingFor && this.props.searchOptions.showCount &&
          <div className='count-section'>
            <span className='number'>{this.props.searchItemCount}</span> <span>{searchCount(this.props.searchItemCount, 'classes', {isBlock: this.props.searchOptions.isBlock})} found</span>
          </div>
        }

        {/* DESKTOP VERSION */}
        {this.props.searchOptions.showLookingFor && <div id='doctor-search-container' className='search-container'>
          {this.renderResultsCount()}
          <div className='looking-for-container'>
            <div id='doctor_search_for_input' className='search-label'>For</div>
            <LookingForClassesConnector setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} enterSubmit={this.enterSubmit} />
          </div>
          <div className='search-button-container'>
            <button className='btn apply-button fad-search-button desktop' onClick={() => this.handleSearch()} >Search</button>
          </div>
        </div>}

        {/* MOBILE VERSION */}
        {this.props.searchOptions.showLookingFor && <div className={`search-container-mobile ${this.props.flow === 'sad' ? 'sad' : ''}`}>
          <div className={collapseTitleStyle} onClick={() => this.toggleCollapse()} >
            {this.renderResultsCountMobile()}
            {collapseIcon}
          </div>
          <div className={collapseStyle}>
            <div>
              {
                <div className='looking-for-container'>
                  <LookingForClassesConnector mobile setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} enterSubmit={this.enterSubmit} />
                </div>
              }
              <div className='search-mobile-container'>
                <button className='btn apply-button fad-search-button mobile' disabled={(!this.state.lookingFor && !this.state.address) && this.props.flow === 'fad'} onClick={() => this.handleSearch()} >Search</button>
              </div>
            </div>
          </div>
        </div>}
        {/* <div className={`hide-for-large filters-aside ${this.state.asideClass}`}>
          <div className='mobile-filter-title-container'>
            <SVGInline className='close-filters' svg={closeIcon} onClick={() => { togglePreventScroll(false); this.setState({ asideClass: 'slide-out' }) }} />
            <div className='mobile-filter-title'>Filter</div>
            {this.props.filterCount > 0 && <div className='mobile-filter-clear' onClick={(e) => this.clearAllFilters(e)}>Clear All</div>}
          </div>
          <div className='mobile-filters-container' style={{height: this.state.innerHeight}}>
            <FindFiltersConnector flow={this.props.flow}
              filterOptions={this.filterOptions}
              searchOptions={this.props.searchOptions}
              urlParameters={this.urlParameters.filters}
              dataLoaded={this.props.dataLoaded && !this.props.filtersLoading}
              mobile />
            <button className='aside-btn' tabIndex='0' style={{top: this.state.innerHeight - 55}} onClick={() => { togglePreventScroll(false); this.setState({ asideClass: 'slide-out' }) }}>{!this.props.dataLoaded ? 'LOADING' : `SEE ${this.props.searchItemCount}`} RESULTS</button>
          </div>
        </div> */}
        {
          this.props.dataLoaded && this.props.searchItemCount === 0 && !this.props.fetchCallFailed
            ? <div className='fad-error-container no-results-message'>
              <h4>No Results Found {this.props.urlParameters && this.props.urlParameters.q ? ('for "' + this.props.urlParameters.q) + '"' : ''}</h4>
              <p>Select from the list of commonly searched classes: </p>
              <ul id='commonly-searched'>
                {
                  this.linkList.map((link, index) => {
                    return (
                      <li key={index}>
                        <a target='_self' href={searchUrl(link)}>{link}</a>
                      </li>
                    )
                  })
                }
              </ul>
              <p>Or</p>
              <p><a href={window.location.href.split('?')[0]}>View all available classes</a></p>
            </div>
            : <div>
              <div id='filter-button-row-header' className={this.state.desktopFixed ? 'row-container fixed-filter-sort-header' : 'row-container button-row'}>
                <div className='filters-container show-for-large'>
                  <div className={`filter-header-label ${this.state.desktopFixed ? 'add-bottom-shadow' : ''}`}><span>Filters</span>
                    {this.props.filterCount > 0 && <div className='clear-all-filters' onClick={(e) => this.clearAllFilters(e)}>Clear All</div>}
                  </div>
                </div>
                <div className={`sort-main-container ${this.state.desktopFixed ? 'add-bottom-shadow' : ''}`}>
                  <button onClick={() => { togglePreventScroll(true); this.setState({ asideClass: 'slide-in' }) }} className='hide-for-large btn filter-btn'>{`Filter ${this.props.filterCount === 0 ? '' : '(' + this.props.filterCount + ')'}`}</button>
                  <div className={this.state.desktopFixed ? 'show-fixed-doctor-count' : 'hide-filter'}>
                    <span className='fixed-doctor-count'>{this.props.searchItemCount}</span>
                    {searchCount(this.props.searchItemCount, 'classes', {})} Found
                  </div>
                </div>
              </div>
              <div className='row-container content'>
                {
                  this.state.showFilters &&
                  <div className={`filters-content show-for-large ${this.state.desktopFixed ? (this.state.desktopBottom ? 'filters-content-absolute' : 'filters-content-fixed') : ''}`}>
                    <div id='provider-filter-container' className='filters-inner'>
                      <div className='filter-group-container'>
                        <FindFiltersConnector flow={this.props.flow}
                          filterOptions={this.filterOptions}
                          searchOptions={this.props.searchOptions}
                          urlParameters={this.urlParameters.filters}
                          dataLoaded={this.props.dataLoaded && !this.props.filtersLoading}
                          mobile={false} />
                      </div>
                    </div>
                  </div>
                }
                <div className={`${this.state.desktopFixed ? 'results-container-fixed' : ''} ${this.props.sort ? this.props.sort.toLowerCase().replace(/\s/g, '-') : ''} results-container classes-result-container`}>
                  {
                    loadsData({
                      dataLoaded: !this.props.searched || this.props.dataLoaded,
                      dataFetchFailed: this.props.fetchCallFailed,
                      generateDataContent: this.generateDataContent,
                      generateLoadingContent: this.generateLoadingContent,
                      generateErrorContent: () => this.generateErrorContent(PATIENT_ERROR_MESSAGE, this.props.runSearch)
                    })
                  }
                </div>
              </div>
            </div>
        }
        <BackToTop />
      </div>
    )
  }
}
